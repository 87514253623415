@use '../../scss/themes/themes.mixin.scss' as *;
@use '../../scss/variables' as *;
@use '../../scss/mixins/fields.mixins.scss' as mixin;

.dropdown-select {
  &__item--optgroup-title{
    color: $grey-disabled !important;
    text-align: center !important;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:hover {
      background: $white !important;
      color: $grey-disabled !important;
      cursor: default;
    }

    &::before,
    &::after {
      display: block;
      content: '';
      height: 2px;
      width: 35px;
      background-color: $grey-disabled;
    }
  }

  .picky {
    &__input {
      @include mixin.form-field();
      margin-top: 5px;
      text-align: left;
      padding-top: 10px;
      cursor: pointer;
      @include themify() {
        color: themed('primaryColor');
      }

      &::after {
        top: 19px;
      }

      &:focus {
        outline: none;
        box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
      }

      span {
        display: inline-block;
        width: 90%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    &__placeholder {
      color: $dark-grey;
    }

    &__filter__input {
      background-color: $white;
      height: 40px !important;
      @include themify() {
        border-color: themed('primaryColor') !important;
      }
    }

    &__dropdown {
      margin-top: 9px;
      @include themify() {
        border-bottom: 1px solid themed('primaryColor');
        border-left: 1px solid themed('primaryColor');
        border-right: 1px solid themed('primaryColor');
      }
      box-shadow: 0px 8px 16px 0px rgba($black, 0.4);
      overflow-y: auto !important;

      .option {
        list-style-type: none;
        @include themify() {
          color: themed('primaryColor');
        }
        background-color: $white;
        input[type='radio'] {
          visibility: hidden;
        }

        &.selected {
          @include themify() {
            background-color: themed('primaryColor');
          }
          color: $white;
        }
      }
    }
  }

  .dropdown-select__hide-filter {
    .picky__filter__input {
      display: none;
    }
  }

  .dropdown-select__placeholder span {
    color: $grey-extra-light;
    font-size: 14px;
    text-align: left;
  }

  .dropdown-select__item {
    @include themify() {
      color: themed('primaryColor');
    }
    border: 0;
    min-height: 41px;
    padding-top: 10px;
    list-style-type: none;

    &:hover {
      @include themify() {
        background-color: themed('primaryColor');
      }
      color: $white;
    }
  }

  .dropdown-select__filter-no-result {
    padding: 10px 15px 0 15px;
    text-align: center;
    @include themify() {
      color: themed('primaryColor');
    }
  }
  .dropdown-select__add-new-value {
    text-align: center;
    text-decoration: underline;
    cursor: pointer;
    @include themify() {
      color: themed('primaryColor') !important;
    }
  }

  .error {
    .picky__input {
      border: 1px solid $red !important;
      &::after {
        color: $red;
      }
    }
  }

  .dropdown-select__disabled {
    pointer-events: none;
    color: grey !important;
    .picky__input--disabled {
      background-color: $white;
      opacity: 0.6;
      border: 1px solid $dark-grey !important;
    }
    .picky__placeholder {
      color: grey !important;
    }
  }
}

div[aria-selected="true"] {
  @include themify() {
    color: themed('primaryColor');
  }
}

div[disabled] {
  color: $grey-extra-light
}

.dropdown-select__label {
  display: flex;
}
