@use '../themes/themes.mixin.scss' as *;
@use '../variables' as *;
@use './tooltip.mixins.scss' as mixin;

@mixin form-field($height: 43px) {
  background-color: $white;
  @include themify() {
    border: 1px solid themed('primaryColor');
  }
  border-radius: 1px;
  height: $height;
  margin-top: 0.4rem;

  + .tooltip {
    @include mixin.tooltip;
  }

  + .tooltip-form {
    position: fixed !important;
  }

  &__disabled {
    background-color: $white;
    border: 1px solid $dark-grey;
    border-radius: 1px;
    opacity: 0.6;
    color: $charcoal-black;
  }

  &__label--disabled {
    color: $grey-disabled;
    opacity: .7;
  }
}
